.it-header-wrapper {
  .it-header-center-wrapper {
    padding: 1rem 0;
    margin: 1rem 0;

    .it-header-center-content-wrapper {
      .it-brand-wrapper {
        .header-logo {
          margin: 1rem 1rem 1rem 0;
        }

        .it-brand-text,
        .it-brand-text h2,
        .it-brand-text h3 {
          color: $text-color;
          font-weight: 700;
        }
      }

      .it-right-zone {
        color: $text-color;

        .it-socials {
          ul {
            .icon {
              color: $unione;
              fill: $unione;
            }

            a:hover svg {
              fill: $secondary;
            }
          }
        }

        .it-search-wrapper a.rounded-icon {
          background-color: $unione;
          color: white;

          &:hover {
            background-color: $secondary;
          }
        }
      }
    }
  }

  .it-header-navbar-wrapper {
    background-color: $primary;
  }

  .navbar .navbar-collapsable {
    .menu-wrapper .it-brand-wrapper {
      .header-logo {
        margin: 0;

        img {
          width: 80px;
          height: 41px;
        }
      }

      .it-brand-text,
      .it-brand-text h2,
      .it-brand-text h3 {
        color: $text-color;
        font-weight: 700;
      }
    }

    .navbar-nav li {
      a.nav-link {
        color: $primary-text;
      }

      // &.megamenu a.nav-link {
      //   font-weight: 700;
      // }
    }
  }

  .megamenu .megamenu-toggle-icon {
    fill: $primary-text;
  }

  .navbar .dropdown-menu .link-list-wrapper ul li a span {
    color: $link-color;
  }

  // .navbar .dropdown-menu .link-list-wrapper .link-list.bordered li {
  //   border-bottom: 1px solid transparentize($color: $primary, $amount: 0.8) !important;
  // }

  @media (max-width: map-get($map: $grid-breakpoints, $key: 'lg') - 1px) {
    .it-header-center-wrapper {
      margin: 0;

      .it-header-center-content-wrapper {
        .it-brand-wrapper {
          .header-logo {
            margin: 0.5rem 0.5rem 0.5rem 1rem;

            img {
              width: 59px;
              height: 30px;
            }
          }
        }

        .it-right-zone .it-search-wrapper a.rounded-icon {
          width: 36px;
          height: 36px;
          border-radius: 50%;
        }
      }
    }

    button.custom-navbar-toggler svg.icon {
      fill: $unione;
    }

    .it-header-navbar-wrapper {
      background-color: transparent;

      > .container {
        padding: 0;
      }
    }

    .it-nav-wrapper .it-header-navbar-wrapper nav {
      padding-right: 0;
    }

    .it-nav-wrapper .it-header-navbar-wrapper {
      margin-top: -20px;
    }
  }
}
