//site
$primary-h: 88;
$primary-s: 62;
$primary-b: 76;

$unione: #007a52;

$primary: #8bc34a;
$primary-text: black;
$secondary: #30373d;
$tertiary: #5a6771;
$tertiary-text: white;

$header-slim-bg-color: $secondary;
$header-center-bg-color: white;

//$headings-color: $primary;
//$semibold: 600;

$link-color: $unione;
